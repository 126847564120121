exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-ceramics-gallery-js": () => import("./../../../src/templates/ceramics-gallery.js" /* webpackChunkName: "component---src-templates-ceramics-gallery-js" */),
  "component---src-templates-conceptual-ceramics-gallery-js": () => import("./../../../src/templates/conceptual-ceramics-gallery.js" /* webpackChunkName: "component---src-templates-conceptual-ceramics-gallery-js" */),
  "component---src-templates-conceptualceramics-js": () => import("./../../../src/templates/conceptualceramics.js" /* webpackChunkName: "component---src-templates-conceptualceramics-js" */),
  "component---src-templates-functional-ceramics-gallery-js": () => import("./../../../src/templates/functional-ceramics-gallery.js" /* webpackChunkName: "component---src-templates-functional-ceramics-gallery-js" */),
  "component---src-templates-functionalceramics-js": () => import("./../../../src/templates/functionalceramics.js" /* webpackChunkName: "component---src-templates-functionalceramics-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-media-clippings-gallery-js": () => import("./../../../src/templates/media-clippings-gallery.js" /* webpackChunkName: "component---src-templates-media-clippings-gallery-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-murals-gallery-js": () => import("./../../../src/templates/murals-gallery.js" /* webpackChunkName: "component---src-templates-murals-gallery-js" */),
  "component---src-templates-murals-js": () => import("./../../../src/templates/murals.js" /* webpackChunkName: "component---src-templates-murals-js" */),
  "component---src-templates-sculptures-gallery-js": () => import("./../../../src/templates/sculptures-gallery.js" /* webpackChunkName: "component---src-templates-sculptures-gallery-js" */),
  "component---src-templates-sculptures-js": () => import("./../../../src/templates/sculptures.js" /* webpackChunkName: "component---src-templates-sculptures-js" */)
}

